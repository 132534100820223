.global {
  margin: 0;
  padding: 0;
}
.container {
  background:#fff
}
.whistle {
  width: 20%;
  fill: #f95959;
  margin: 100px 40%;
  text-align: left;
  transform: translate(-50%, -50%);
  transform: rotate(0);
  transform-origin: 80% 30%;
  animation: wiggle 0.2s infinite;
}

@keyframes wiggle {
  0% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(3deg);
  }
}
h1 {
  margin-top: -100px;
  margin-bottom: 20px;
  color: #facf5a;
  text-align: center;
  font-family: "Raleway";
  font-size: 90px;
  font-weight: 800;
}
h2 {
  color: #455d7a;
  text-align: center;
  font-family: "Raleway";
  font-size: 30px;

}
.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}