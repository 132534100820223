.container{
    width: 100%;
  }
  
  .input{
    width: 100%; 
    font-size: 14px !important;
  }
  
  .eye, .info{
    width: 2em;
    margin-bottom: 10px;
    margin-right: 6px;
    outline: none!important;
  }